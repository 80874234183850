import $ from 'jquery';
let active = false;

function updateState($wrap, callbacks) {
  active = !active;
  $wrap
    .find('.flipper__hero')
    .toggleClass('is-active', active);

  callbacks.onChange($wrap);
  if(active) {
    callbacks.onFocus($wrap);
  }
  else {
    callbacks.onBlur($wrap);
  }
}

function handleClick(callbacks) {
  return e => {
    const $wrap = $(e.currentTarget);
    if(!active) {
      updateState($wrap, callbacks);
    }
    e.stopImmediatePropagation();
  }
}

function handleClickOutside(callbacks) {
  return e => {
    const $target = $(e.target);
    const $wrap = $target.closest('.flipper');

    // if modal is active and we click outside it
    if(active && $wrap.length <= 0) {
      updateState($('.flipper'), callbacks);
      e.stopImmediatePropagation();
    }
  }
}

export default function Flipper(options = {}) {
  const defaults = {
    callbacks: {
      onChange() {},
      onBlur() {},
      onFocus() {}
    }
  };
  const settings = $.extend({}, defaults, options);
  $('.flipper').on('click', handleClick(settings.callbacks));
  $(document).on('click', handleClickOutside(settings.callbacks));
}