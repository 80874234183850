import $ from 'jquery';

export default async function() {
  const $carousel = $('.carousel');

  if ($carousel.length > 0) {
    try {
      await import(/* webpackChunkName: "slider" */ 'slick-carousel');

      $carousel.each((i, el) => {
        const $el = $(el);
        const $list = $el.find('.carousel__list');
        const $controls = $el.find('.carousel__controls');
      
        $controls.find('.carousel__controls-item').on('click touchend', e => {
          const $control = $(e.currentTarget);
          $list.slick('slickGoTo', parseInt($control.attr('data-control')));
          e.preventDefault();
        });
      
        $list.on('init', function(event, slick){
          $controls.closest('.carousel').addClass('is-active');
          $controls.find(`.carousel__controls-item[data-control="0"]`).addClass('is-active');
        });
      
        $list.on('afterChange', function(event, slick, currentSlide){
          $controls.find('.carousel__controls-item').removeClass('is-active');
          $controls.find(`.carousel__controls-item[data-control="${currentSlide}"]`).addClass('is-active');
        });
      
        $list.slick({
          fade: true,
          autoplay: true,
          autoplaySpeed: 7000,
          arrows: false,
          adaptiveHeight: false,
          waitForAnimate: false,
          responsive: [{
            breakpoint: 1024,
            settings: {
              fade: true,
              autoplay: false,
              adaptiveHeight: true,
              arrows: false,
              dots: true,
            }
          }]
        });
      });
    }
    catch (e) {
      console.log(e);
    }
  }
}