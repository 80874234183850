import $ from 'jquery';

function handleClick(cb) {
  return (e) => {
    const $elem = $(e.currentTarget);
    const target = $elem.attr('href');
    const $target = $(target);
    const callback = cb($elem, $target);

    if($target.length) {
      e.preventDefault();
      scrollTo($target, {}, callback);
    }
  }
}

export function scrollTo($target, ops = {}, cb = function() {}) {
  const { threshold = 0 } = ops;
  $('html, body').animate({
    scrollTop: $target.offset().top - threshold
  }, 1000, cb);
}

export function SmoothScroll(selector, cb) {
  $(document).on('click', selector, handleClick(cb));
}