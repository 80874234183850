import $ from 'jquery';
import GoogleMapsLoader from 'google-maps';
import styles from '../maps_style';

export default async function($container) {
  GoogleMapsLoader.KEY = 'AIzaSyCc5iySp-75MgUrCxJSiU4Epch6GljSUdo';
  GoogleMapsLoader.VERSION = 3.34;
  GoogleMapsLoader.load(function(google) {

    // there may be more than one map
    $container.each((_, el) => {
      const $el = $(el);
      const mapId = $el.attr('id');
      const settings = JSON.parse($(`[data-settings-selector=${mapId}]`).html());
      const { marker } = settings;

      const icon = {
        url: settings.pin_path, // url
        scaledSize: new google.maps.Size(40, 64)
      };

      const map = new google.maps.Map(el, {
        styles,
        center: settings.position,
        zoom: 17,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false
      });

      // there may be more than one marker
      let markerArray = [];
      $(marker).each((_, markerItem) => {
        const { position, title, content } = markerItem;
        const marker = new google.maps.Marker({ position, map, title, icon });
        const infowindow = new google.maps.InfoWindow({ content });
        marker.addListener('click', function() {
          infowindow.open(map, marker);
        });
        markerArray[markerArray.length] = { marker, infowindow };
      });

      // mark the map container
      $el.addClass('is-loaded');

      // attach data to map container
      $el.data('map', {
        resize(time = 0) {
          const center = map.getCenter();
          setTimeout(() => {
            google.maps.event.trigger(map, 'resize');
            map.setCenter(center); 
          }, time);
        },
        closeWindows() {
          markerArray.forEach(item => {
            if (item.infowindow) {
              item.infowindow.close();
            }
          });
        }
      });
    });

  });
}
