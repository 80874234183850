import $ from 'jquery';
function testForText(wrap, typing = false) {
  return e => {
    const $item = $(e.currentTarget);
    $item.closest(wrap)
      .toggleClass('has-value', ($item.val() !== ''))
      .toggleClass('has-interaction', typing);
  };
}

export default function Forms(id) {
  const $forms = $(`.${id}`);
  const $fields = $forms.find(`.${id}__item`);

  $fields.find('input, textarea').on({
    focus: testForText(`.${id}__item`, true),
    blur: testForText(`.${id}__item`)
  });
}